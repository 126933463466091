import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetentionReportComponent } from './detention-report/detention-report.component';
import { ScBlacklistReportComponent } from './sc-blacklist-report/sc-blacklist-report.component';
import { TowRequestsReportComponent } from './tow-requests-report/tow-requests-report.component';
import { NotificationReportComponent } from './notification-report/notification-report.component';
import { DraggingNotificationComponent } from './dragging-notification/dragging-notification.component';
import { CarStatusReportComponent } from './car-status-report/car-status-report.component';
import { VerifyVehiclesReportComponent } from './verify-vehicles-report/verify-vehicles-report.component';

const routes: Routes = [
    { path: 'towRequestsReport', component: TowRequestsReportComponent },
    { path: 'detentionReport', component: DetentionReportComponent },
    { path: 'scBlacklistReports', component: ScBlacklistReportComponent },
    // { path: 'amanaViolationNotificationReport', component: NotificationReportComponent },
    // { path: 'amanaDraggingNotificationReport', component: DraggingNotificationComponent },
    { path: 'checkCarStatusReport', component: CarStatusReportComponent },
    { path:'verifyVehiclesReport', component: VerifyVehiclesReportComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReportsRoutingModule { }
