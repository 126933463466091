import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { EditVehicleDialogComponent } from './edit-vehicle-dialog/edit-vehicle-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-verify-vehicles-report',
  templateUrl: './verify-vehicles-report.component.html',
  styleUrls: ['./verify-vehicles-report.component.scss'],
})
export class VerifyVehiclesReportComponent implements OnInit {
  sending: boolean = false;
  searchForm: FormGroup;
  statuses = ['Active', 'Inactive', 'Pending'];
  failureReasons = ['Network Issue', 'Server Error', 'Timeout', 'Other'];
  loading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataList = [
    {
      plateNumber: '1234 ABC',
      citationNumber: '3543435',
      citationStatus: 'Settled',
      date: '01/20/2025',
      registrationType: 'Private',
      status: 'Failed',
      failureReason: 'Other',
      registrationColor: 'red',
      evidence: [
        'assets/Icons/Arabic Flag.svg',
        'assets/Icons/eden-icon.png',
        'assets/Icons/Arabic Flag.svg',
        'assets/Icons/Arabic Flag.svg',
      ],
    },
    {
      plateNumber: '5678 DEF',
      citationNumber: '9876543',
      citationStatus: 'Voided',
      date: '01/18/2025',
      registrationType: 'Public Transportation',
      status: 'Failed',
      failureReason: 'Unavailable',
      registrationColor: 'green',
      evidence: [
        'assets/icons/Arabic Flag.svg',
        'assets/icons/Arabic Flag.svg',
      ],
    },
    {
      plateNumber: '9101 GHI',
      citationNumber: '1122334',
      citationStatus: 'Opened',
      date: '01/15/2025',
      registrationType: 'Taxi',
      status: 'Success',
      failureReason: '',
      registrationColor: 'blue',
      evidence: ['assets/evidence1.jpg', 'assets/evidence2.jpg'],
    },
  ];
  dataSource: MatTableDataSource<any>;

  @ViewChildren('numberInput') numberInputs!: QueryList<ElementRef>;
  @ViewChildren('letterInput') letterInputs!: QueryList<ElementRef>;

  registrationTypes = [
    { name: 'Private', color: 'red' },
    { name: 'Public Transportation', color: 'blue' },
    { name: 'Taxi', color: 'green' },
    { name: 'Diplomatic', color: 'orange' },
  ];

  selectedData: any;
  selectedRegistrationType: string | null = null;

  plateNumberForm = new FormGroup({
    numbers: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    letters: new FormControl('', [
      Validators.required,
      Validators.pattern('^[A-Za-z]*$'),
    ]),
  });

  constructor(private fb: FormBuilder, private _dialog: MatDialog) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.searchForm = this.fb.group({
      fromDate: [null],
      toDate: [null],
      status: [[]],
      failureReasons: [[]],
    });
  }

  onSendAll() {}

  onSubmit() {
    if (this.searchForm.valid) {
      console.log('Form Submitted:', this.searchForm.value);
    } else {
      console.log('Form is not valid');
    }
  }

  openSidebar(data: any): void {
    const dialogRef = this._dialog.open(EditVehicleDialogComponent, {
      width: '600px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Handle the dialog result
        console.log('Dialog result:', result);
        // Update your data as needed
      }
    });
  }

  sendUpdate(): void {
    console.log('Selected Registration Type:', this.selectedRegistrationType);
    console.log('Selected Data:', this.selectedData);
  }

  onResend(data: any): void {
    console.log('Resend action for:', data);
  }

  onClear() {
    this.searchForm.reset();
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event?.pageSize;
    this.currentPage = event?.pageIndex;
    const formValues = this.searchForm.value;
    console.log(formValues);
    // this.getDataList(this.currentPage, this.pageSize, formValues);
  }
}
