import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SystemParametersService } from '../system-parameters.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-operation-profile',
  templateUrl: './operation-profile.component.html',
  styleUrls: ['./operation-profile.component.scss'],
})
export class OperationProfileComponent implements OnInit {
  selectedOperation: number = 1;
  lookups: any[] = [];
  operationSites = [
    { id: 1, operationSiteName: 'Dammam' },
    { id: 2, operationSiteName: 'Al Khobar' },
    { id: 3, operationSiteName: 'Qassim' },
  ];
  displayedColumns: string[] = ['name', 'description', 'value'];
  loading = false;
  isFormEdited = false;

  constructor(
    private systemParametersService: SystemParametersService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.lookups['SCAN_CAPABILITY'] = [
      { value: false, label: 'No' },
      { value: true, label: 'Yes' },
    ];
    this.lookups['CREATE_INBOUND_REQUEST'] = [
      { value: false, label: 'No' },
      { value: true, label: 'Yes' },
    ];
    this.lookups['BLACKLIST_PRIORITY'] = [
      { value: 'EARLIEST', label: 'Earliest' },
      { value: 'LATEST', label: 'Latest' },
    ];
    this.lookups['TOW_MODEL'] = [
      { value: 'WRECKER', label: 'Wrecker' },
      { value: 'DISPATCHER', label: 'Dispatcher' },
    ];

    this.getSystemParamsForOperation(this.selectedOperation);
  }

  configurationData = new MatTableDataSource([]);
  systemParamsData: any;

  @ViewChild(MatSort) sort: MatSort;
  ngAfterViewInit() {
    this.configurationData.sort = this.sort;
  }

  onOperationSiteClicked(operation: number): void {
    this.selectedOperation = operation;
    this.getSystemParamsForOperation(operation);
  }

  getSystemParamsForOperation(operation: number): void {
    console.log(`Handling action for ${operation}`);
    this.loading = true;
    this.systemParametersService.getSystemParameters(operation).subscribe({
      next: (data) => {
        this.configurationData = new MatTableDataSource(data?.payload);
        this.configurationData.sort = this.sort;
        this.systemParamsData = data?.payload;
        this.loading = false;
        this.isFormEdited = false;
      },
      error: (error) => {
        console.error('Error fetching system parameters:', error);
        this.loading = false;
        this.toaster.error('Error fetching system parameters');
      },
    });
  }

  onInputChange(): void {
    this.isFormEdited = true;
  }

  onSaveSystemParameters(): void {
    const systemValues = { systemConfigValues: {} };
    this.systemParamsData.forEach((element) => {
      systemValues.systemConfigValues[element.id] = element.value;
    });
    this.systemParametersService.editSystemParameters(systemValues).subscribe({
      next: (data) => {
        console.log('System parameters updated successfully:', data);
        this.toaster.success('System parameters updated successfully');
        this.isFormEdited = false;
      },
      error: (error) => {
        this.toaster.error('Error updating system parameters');
        console.error('Error updating system parameters:', error);
      },
    });
  }
}
