<div class="d-flex justify-content-between align-items-center">
  <h1 class="fw-bolder">Operation Profile</h1>
  <button
    [disabled]="!isFormEdited"
    (click)="onSaveSystemParameters()"
    class="save-btn"
  >
    Save Changes
  </button>
</div>

<div class="mt-4">
  <button
    *ngFor="let operationSite of operationSites"
    [ngClass]="{ active: selectedOperation === operationSite.id }"
    (click)="onOperationSiteClicked(operationSite.id)"
  >
    {{ operationSite.operationSiteName }}
  </button>
</div>

<table
  *ngIf="!loading"
  mat-table
  [dataSource]="configurationData"
  matSort
  class="mat-elevation-z8"
>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <!-- Description Column -->
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
    <td mat-cell *matCellDef="let element">{{ element.description }}</td>
  </ng-container>

  <!-- Value Column -->
  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
    <td mat-cell *matCellDef="let element">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <!-- Switch on dataType -->
          <ng-container [ngSwitch]="element.dataType">
            <!-- Number input for INTEGER and DOUBLE -->
            <ng-container *ngSwitchCase="'INTEGER'">
              <div
                *ngIf="element.code === 'OPERATION_TIME_END_DAILY'"
                class="form-group"
              >
                <input
                  class="form-control"
                  type="number"
                  min="0"
                  max="24"
                  [(ngModel)]="element.value"
                  (ngModelChange)="onInputChange()"
                  (blur)="
                    element.value = element.value > 24 ? 24 : element.value || 0
                  "
                />
              </div>
              <div
                class="form-group"
                *ngIf="element.code !== 'OPERATION_TIME_END_DAILY'"
              >
                <input
                  class="form-control"
                  type="number"
                  min="0"
                  [(ngModel)]="element.value"
                  (ngModelChange)="onInputChange()"
                  (blur)="element.value = element.value || 0"
                />
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'DOUBLE'">
              <div class="form-group">
                <input
                  class="form-control"
                  type="number"
                  min="0"
                  step="any"
                  [(ngModel)]="element.value"
                  (ngModelChange)="onInputChange()"
                  (blur)="element.value = element.value || 0"
                />
              </div>
            </ng-container>

            <!-- Dropdown for STRING and BOOLEAN -->
            <ng-container *ngSwitchCase="'STRING'">
              <div class="form-group">
                <select
                  class="form-select"
                  [(ngModel)]="element.value"
                  (ngModelChange)="onInputChange()"
                >
                  <option
                    *ngFor="let option of lookups[element.code]"
                    [value]="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'BOOLEAN'">
              <div class="form-group">
                <select
                  class="form-select"
                  [(ngModel)]="element.value"
                  (ngModelChange)="onInputChange()"
                >
                  <option
                    *ngFor="let option of lookups[element.code]"
                    [value]="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </ng-container>

            <!-- Default case (should be masked input) -->
            <ng-container *ngSwitchDefault>
              <div class="form-group">
                <input
                  class="form-control"
                  [attr.placeholder]="element.unit || 'Enter value'"
                  [(ngModel)]="element.value"
                  (ngModelChange)="onInputChange()"
                />
              </div>
            </ng-container>
          </ng-container>
        </div>

        <!-- Input mask section (only for units other than LOOKUP) -->
        <div *ngIf="element.unit !== 'LOOKUP'" class="input-mask">
          <p>{{ element.unit }}</p>
        </div>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-spinner *ngIf="loading"></mat-spinner>
