import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OperationProfileComponent } from './operation-profile/operation-profile.component';

const routes: Routes = [{ path: '', component: OperationProfileComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SystemParametersRoutingModule { }
