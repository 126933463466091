import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SystemParametersService {
  constructor(private http: HttpClient) {}
  token = `Bearer ${localStorage.getItem('token')}`;

  getSystemParameters(id: number): any {
    return this.http.get(
      `${env.baseURL}operation/systemConfig/operationId/${id}`,
      {
        headers: { Authorization: this.token },
      }
    );
  }

  editSystemParameters(configurations: any): any {
    return this.http.post(
      `${env.baseURL}operation/systemConfig`,
      configurations,
      {
        headers: { Authorization: this.token },
      }
    );
  }
}
