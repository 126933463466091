import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-vehicle-dialog',
  templateUrl: './edit-vehicle-dialog.component.html',
  styleUrls: ['./edit-vehicle-dialog.component.scss'],
})
export class EditVehicleDialogComponent implements OnInit {
  plateNumberForm: FormGroup;
  selectedRegistrationType: string | null = null;
  selectedImage: string;
  registrationTypes = [
    { name: 'Private', color: 'red' },
    { name: 'Public Transportation', color: 'blue' },
    { name: 'Taxi', color: 'green' },
    { name: 'Diplomatic', color: 'orange' },
  ];

  private readonly numberPattern = '^[0-9]';
  private readonly letterPattern = '^[A-Za-z]';

  constructor(
    public dialogRef: MatDialogRef<EditVehicleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initializeForm();

    if (this.data.plateNumber) {
      this.bindEditingDataToForm();
    }
  }

  initializeForm() {
    this.plateNumberForm = this.fb.group({
      number1: [
        '',
        [Validators.required, Validators.pattern(this.numberPattern)],
      ],
      number2: [
        '',
        [Validators.required, Validators.pattern(this.numberPattern)],
      ],
      number3: [
        '',
        [Validators.required, Validators.pattern(this.numberPattern)],
      ],
      number4: [
        '',
        [Validators.required, Validators.pattern(this.numberPattern)],
      ],
      letter1: [
        '',
        [Validators.required, Validators.pattern(this.letterPattern)],
      ],
      letter2: [
        '',
        [Validators.required, Validators.pattern(this.letterPattern)],
      ],
      letter3: [
        '',
        [Validators.required, Validators.pattern(this.letterPattern)],
      ],
    });
  }

  bindEditingDataToForm() {
    const numbers = this.data.plateNumber.match(/\d+/)[0].split('');
    const letters = this.data.plateNumber.match(/[A-Za-z]+/)[0].split('');

    numbers.forEach((num: string, index: number) => {
      this.plateNumberForm.get(`number${index + 1}`)?.setValue(num);
    });

    letters.forEach((letter: string, index: number) => {
      this.plateNumberForm.get(`letter${index + 1}`)?.setValue(letter);
    });

    this.selectedRegistrationType = this.data.registrationType;
  }

  onNumberInput(event: any) {
    const input = event.target;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  onLetterInput(event: any) {
    const input = event.target;
    input.value = input.value.replace(/[^A-Za-z]/g, '');
  }

  moveToNext(event: any) {
    const input = event.target;
    if (input.value.length === input.maxLength) {
      const next = input.nextElementSibling;
      if (next) {
        next.focus();
      }
    }
  }

  onSend(): void {
    const numbers = [
      this.plateNumberForm.get('number1').value,
      this.plateNumberForm.get('number2').value,
      this.plateNumberForm.get('number3').value,
      this.plateNumberForm.get('number4').value,
    ].join('');

    const letters = [
      this.plateNumberForm.get('letter1').value,
      this.plateNumberForm.get('letter2').value,
      this.plateNumberForm.get('letter3').value,
    ].join('');

    this.dialogRef.close({
      plateNumber: numbers + letters,
      registrationType: this.selectedRegistrationType,
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
