<div class="dialog-title">
  <h3 mat-dialog-title>Update Vehicle</h3>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <section>
    <h3 class="fw-bold">Evidences</h3>
    <div class="evidence-container">
      <div class="evidence-preview">
        <img
          [src]="selectedImage || data.evidence[0]"
          alt="Evidence"
          class="main-image"
        />
      </div>
      <div class="evidence-thumbnails">
        <img
          *ngFor="let img of data.evidence"
          [src]="img"
          alt="Thumbnail"
          class="thumbnail"
          (click)="selectedImage = img"
          [class.active]="selectedImage === img"
        />
      </div>
    </div>
  </section>

  <section class="mt-3">
    <form [formGroup]="plateNumberForm">
      <h3 class="fw-bold">Plate Number</h3>
      <div class="plate-number-container">
        <div class="numbers-section">
          <input
            type="text"
            maxlength="1"
            inputmode="numeric"
            [formControlName]="'number' + (i + 1)"
            class="plate-input number"
            *ngFor="let i of [0, 1, 2, 3]"
            (input)="onNumberInput($event)"
            (keyup)="moveToNext($event)"
          />
        </div>
        <div class="letters-section">
          <input
            type="text"
            maxlength="1"
            [formControlName]="'letter' + (i + 1)"
            class="plate-input letter"
            *ngFor="let i of [0, 1, 2]"
            (input)="onLetterInput($event)"
            (keyup)="moveToNext($event)"
            (keydown.backspace)="onLetterInput($event)"
          />
        </div>
      </div>
    </form>  </section>

  <section class="mt-3">
    <h3 class="fw-bold">
      Registration Type <span class="text-danger">*</span>
    </h3>
    <mat-form-field appearance="fill" class="dropdown-field">
      <mat-label>Registration Type</mat-label>
      <mat-select [(ngModel)]="selectedRegistrationType">
        <mat-option *ngFor="let type of registrationTypes" [value]="type.name">
          <span
            class="registration-type-indicator-dd"
            [ngStyle]="{ 'background-color': type.color }"
          ></span>
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>
</div>

<div mat-dialog-actions>
  <button class="btn clear-btn fw-bold" (click)="onCancel()">Cancel</button>
  <button
    [disabled]="!plateNumberForm.valid || !selectedRegistrationType"
    class="btn submit-btn fw-bold"
    (click)="onSend()"
  >
    Send
  </button>
</div>
