import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../_shared/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { OperationProfileComponent } from './operation-profile/operation-profile.component';
import { SystemParametersRoutingModule } from './system-parameters.routing';

@NgModule({
  declarations: [OperationProfileComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatTabsModule,
    SystemParametersRoutingModule,
  ],
})
export class SystemParametersModule {}
