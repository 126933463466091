<div>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="fw-bolder">Verify Vehicle with MOMAH</h1>
    <button
      type="button"
      [disabled]="sending"
      (click)="onSendAll()"
      class="send-all-btn"
    >
      Send All Unavailable
    </button>
  </div>

  <div class="search-form mt-4">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <!-- Start Date -->
        <div class="col-md-3">
          <label for="fromDate">Start Date</label>
          <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
            <input
              matInput
              [matDatepicker]="fromDate"
              placeholder="dd/mm/yyyy"
              formControlName="fromDate"
              id="fromDate"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="fromDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- End Date -->
        <div class="col-md-3">
          <label for="toDate">End Date</label>
          <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
            <input
              matInput
              [min]="searchForm?.value?.fromDate"
              placeholder="dd/mm/yyyy"
              [matDatepicker]="toDate"
              formControlName="toDate"
              id="toDate"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="toDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Status -->
        <div class="col-md-3">
          <label for="operation">Status</label>
          <mat-form-field appearance="outline" class="w-100 px-0">
            <mat-select
              formControlName="status"
              id="status"
              multiple
              placeholder="Choose status"
            >
              <mat-option *ngFor="let status of statuses" [value]="status">{{
                status
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Failure Reason -->
        <div class="col-md-3">
          <label for="operation">Failure Reasons</label>
          <mat-form-field appearance="outline" class="w-100 px-0">
            <mat-select
              formControlName="failureReasons"
              id="failureReasons"
              multiple
              placeholder="Choose failure reasons"
            >
              <mat-option
                *ngFor="let reason of failureReasons"
                [value]="reason"
                >{{ reason }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="mt-3 d-flex justify-content-end">
        <button
          class="btn search-btn px-5 h4 text-center fw-bold text-white"
          type="submit"
        >
          Search
        </button>
        <button
          class="btn filters-btn-clear h4 text-center mx-1 px-5 text-white fw-bold"
          (click)="onClear()"
        >
          Clear
        </button>
      </div>
    </form>
  </div>

  <section class="mb-5">
    <div class="mat-elevation-z8 custom-table-card">
      <table class="col-12 rounded bordered-table mt-3">
        <thead class="rounded tableHead">
          <tr class="text-white">
            <th class="py-3 px-4">Vehicle plate</th>
            <th class="py-3 px-4">Citation Number</th>
            <th class="py-3 px-4">Citation Status</th>
            <th class="py-3 px-4">Date</th>
            <th class="py-3 px-4">Registration Type</th>
            <th class="py-3 px-4">Status</th>
            <th class="py-3 px-4">Failed Reason</th>
            <th class="py-3 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of dataList; let i = index">
            <tr>
              <td class="fs-6 py-2 px-3">{{ data?.plateNumber }}</td>
              <td class="fs-6 py-2 px-3">{{ data?.citationNumber }}</td>
              <td class="fs-6 py-2 px-3">
                <span
                  [ngClass]="{
                    'status-success': data?.citationStatus === 'Settled',
                    'status-pending': data?.citationStatus === 'Voided',
                    'status-fail': data?.citationStatus === 'Opened'
                  }"
                  >{{ data?.citationStatus }}</span
                >
              </td>

              <td class="fs-6 py-2 px-3">
                {{ data?.date | date : "MM/dd/yyyy" }}
              </td>

              <td class="fs-6 py-2 px-3 d-flex align-items-center">
                <span
                  class="registration-type-indicator"
                  [ngStyle]="{ 'background-color': data?.registrationColor }"
                ></span>
                <span class="ms-2 text-nowrap text-truncate">{{
                  data?.registrationType
                }}</span>
              </td>

              <td class="fs-6 py-2 px-3">
                <span
                  [ngClass]="
                    data?.status === 'Success'
                      ? 'status-success'
                      : 'status-fail'
                  "
                  >{{ data?.status }}</span
                >
              </td>

              <td class="fs-6 py-2 px-3">
                <span
                  *ngIf="data?.failureReason"
                  [ngClass]="{
                    'status-pending': data?.failureReason === 'Unavailable',
                    'status-other': data?.failureReason !== 'Unavailable'
                  }"
                  >{{ data?.failureReason }}</span
                >
              </td>

              <td class="fs-6 py-2 px-3">
                <button
                  *ngIf="
                    data?.status === 'Failed' &&
                    data?.failureReason !== 'Unavailable'
                  "
                  mat-icon-button
                  color="primary"
                  (click)="openSidebar(data)"
                  title="Edit"
                >
                  <img src="assets/Icons/edit-icon.svg" alt="edit" />
                </button>
                <button
                  *ngIf="
                    data?.status === 'Failed' &&
                    data?.failureReason === 'Unavailable'
                  "
                  mat-icon-button
                  color="accent"
                  (click)="onResend(data)"
                  title="Resend"
                >
                  <img src="assets/Icons/send.svg" alt="edit" />
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <mat-paginator
        class="mat-paginator-sticky"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
        [length]="totalRows"
        showFirstLastButtons
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </div>
    <mat-spinner *ngIf="loading"></mat-spinner>
  </section>
</div>
